import { Popover, PopoverContent, PopoverTrigger } from '@components/popover';
import { FilterConfigItem, useFilters } from '@ctx/filters/context';
import { cn } from '@utils/cn';
import { ChevronDown, RotateCcw } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function FilterItem({
  configItem,
  displayedValue,
  children,
}: {
  configItem?: FilterConfigItem;
  displayedValue: string;
  children: React.ReactNode;
}) {
  const { t } = useTranslation('app');
  const { addFilter } = useFilters();
  const [open, setOpen] = useState(false);

  if (!configItem) return;
  const groupeId = configItem?.groupe?.length ? configItem.id.split('_')[0] : configItem.id;
  const groupedIds = configItem.groupe?.map((el) => el.id) || [];

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex cursor-pointer gap-2 rounded bg-filter p-4 lg:p-2">
          <strong className="flex items-center gap-1 text-xs font-semibold text-primary">
            {t(`filters.${groupeId}.label`)}
            {displayedValue}
          </strong>
          <div className="flex gap-1">
            {displayedValue && (
              <>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    const filters = [configItem.id, ...groupedIds];
                    filters.forEach((filterId) => addFilter(filterId));
                  }}
                  className="text-gray-400 hover:text-gray-600">
                  <RotateCcw size={14} />
                </button>
              </>
            )}
            {!displayedValue && (
              <ChevronDown
                className={cn(
                  'ms-1 h-4 w-4 origin-center opacity-50',
                  open ? '-scale-y-100 text-primary opacity-100' : '',
                )}
              />
            )}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <div className={cn(configItem?.groupe?.length && 'space-y-4')}>{children}</div>
      </PopoverContent>
    </Popover>
  );
}
