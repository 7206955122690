import { Button } from '@components/button';
import { TimezonesCombobox } from '@components/comboboxes/timezones';
import { Checkbox } from '@components/form/checkbox';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@components/form/form';
import { FileInput } from '@components/form/fileInput';
import { Input } from '@components/form/input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACCEPTED_IMAGE_TYPES, MAX_FILE_SIZE, useAcquirerForm } from '@hooks/forms/acquirers';
import { LogoPreview } from './logoPreview';

export function AcquirerForm({
  formObj,
  submitText,
}: {
  formObj: ReturnType<typeof useAcquirerForm>;
  submitText: string;
}) {
  const { t } = useTranslation('app');
  const [file, setFile] = useState<File | null>(null);

  const { form, onSubmit, error, isPending } = formObj;

  return (
    <Form {...form}>
      <form
        id="userForm"
        className="flex flex-col gap-6"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="logo"
          render={({ field: { onChange } }) => (
            <FormItem className="h-full">
              <FormLabel>{t('form.logo.label')}</FormLabel>
              <FileInput
                file={file}
                title="Logo"
                maxSize={MAX_FILE_SIZE}
                resolution={{ width: 200, height: 200 }}
                acceptedTypes={ACCEPTED_IMAGE_TYPES}
                setFile={(f) => {
                  onChange(f);
                  return setFile(f);
                }}
                preview={
                  (file || form.getValues('logoUrl')) && (
                    <LogoPreview file={file} logoUrl={form.getValues('logoUrl')} />
                  )
                }
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.acquirerName.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="pgIdentifier"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.acquirerPgIdentifier.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="timezone"
          render={({ field: { value, onChange } }) => (
            <FormItem>
              <FormLabel required>{t('form.timezone.label')}</FormLabel>
              <TimezonesCombobox value={value} onSelect={onChange} className="w-full" />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="twoFactorRequired"
          render={({ field }) => (
            <FormItem className="flex space-x-3 space-y-0 bg-transparent">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    form.setValue('twoFactorRequired', Boolean(checked));
                  }}
                />
              </FormControl>
              <FormLabel className="static">{t('form.twoFactorRequired.label')}</FormLabel>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="statementDescriptorAllowed"
          render={({ field }) => (
            <FormItem className="flex space-x-3 space-y-0 bg-transparent">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    form.setValue('statementDescriptorAllowed', Boolean(checked));
                  }}
                />
              </FormControl>
              <FormLabel className="static">{t('form.statementDescriptorAllowed.label')}</FormLabel>
              <FormMessage />
            </FormItem>
          )}
        />
        {error && <p className="text-red-500">{error}</p>}

        <Button type="submit" variant="primary" disabled={isPending} loading={isPending}>
          {submitText}
        </Button>
      </form>
    </Form>
  );
}
