import { buttonVariants } from '@components/button/variants';
import { Loader } from '@components/loader';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { Link, Outlet, createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { useGet } from '@services/staff/acquirers/get';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@components/card';
import { TabLink, TabsContainer } from '@components/tabLink';
import { CalendarClock, Fingerprint, KeyRound, Landmark } from 'lucide-react';
import { Page404 } from '@pages/404';
import { Logo } from '@components/logo';

const AcquirerPage = () => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { acquirerId, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId',
  });
  const { data, isPending } = useGet(acquirerId);

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <BaseLayout
      isChild
      title={
        <div className="flex items-center gap-2">
          <Logo imgSrc={data?.logoUrl || ''} name={data.name}></Logo>
          {data.name}
        </div>
      }
      actions={[
        verifyUserPermission('acquirers_update') && (
          <Link
            key="edit"
            to="/u/$localId/staff/acquirers/$acquirerId/edit"
            params={{ acquirerId: data.id, localId }}
            replace={true}
            className={buttonVariants({ variant: 'primary' })}>
            {t('actions.edit')}
          </Link>
        ),
      ]}>
      <DetailsCard>
        <DetailsCardItem
          icon={<Fingerprint size={26} />}
          label={t('table.id')}
          data={data.id}
          column={1}
        />
        <DetailsCardItem
          icon={<Landmark size={26} />}
          label={t('table.name')}
          data={data.name}
          column={1}
        />
        <DetailsCardItem
          icon={<Fingerprint size={26} />}
          label={t('table.pgIdentifier')}
          data={data.pgIdentifier}
          column={1}
        />
        <DetailsCardItem
          icon={<CalendarClock size={26} />}
          label={t('table.timezone')}
          data={data.timezone}
          column={2}
        />
        <DetailsCardItem
          icon={<KeyRound size={26} />}
          label={t('table.twoFactorRequired')}
          data={data.twoFactorRequired.toString()}
          column={2}
        />
        <DetailsCardItem
          icon={<KeyRound size={26} />}
          label={t('table.statementDescriptorAllowed')}
          data={data.statementDescriptorAllowed.toString()}
          column={2}
        />
      </DetailsCard>

      <Card>
        <CardContent className="scroll-m-16 py-3 pb-10" id="outletNav">
          <TabsContainer>
            {verifyUserPermission('orders_read') && (
              <TabLink
                label={t('nav.orders')}
                path={'/u/$localId/staff/acquirers/$acquirerId/orders'}
                params={{ acquirerId: data.id, localId }}
              />
            )}
            {verifyUserPermission('orders_read') && (
              <TabLink
                label={t('nav.transactions')}
                path={'/u/$localId/staff/acquirers/$acquirerId/transactions'}
                params={{ acquirerId: data.id, localId }}
              />
            )}
            {verifyUserPermission('merchants_read') && (
              <TabLink
                label={t('nav.merchants')}
                path={'/u/$localId/staff/acquirers/$acquirerId/merchants'}
                params={{ acquirerId: data.id, localId }}
              />
            )}
            {verifyUserPermission('acquirer_users_read') && (
              <TabLink
                label={t('nav.users')}
                path={'/u/$localId/staff/acquirers/$acquirerId/users'}
                params={{ acquirerId: data.id, localId }}
              />
            )}
            {verifyUserPermission('acquirer_links_read') && (
              <TabLink
                label={t('nav.paymentControls')}
                path={'/u/$localId/staff/acquirers/$acquirerId/payment-controls'}
                params={{ acquirerId: data.id, localId }}
              />
            )}
            {verifyUserPermission('acquirer_links_read') && (
              <TabLink
                label={t('nav.links')}
                path={'/u/$localId/staff/acquirers/$acquirerId/links'}
                params={{ acquirerId: data.id, localId }}
              />
            )}
            {verifyUserPermission('api_keys_read') && (
              <TabLink
                label={t('nav.apiKeys')}
                path={'/u/$localId/staff/acquirers/$acquirerId/api-keys'}
                params={{ acquirerId: data.id, localId }}
              />
            )}
          </TabsContainer>
          <Outlet />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirers_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerPage,
});
