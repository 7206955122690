import { ComboBoxItemType, Combobox } from '@components/combobox';
import { Label } from '@components/form/label';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useFilters } from '@ctx/filters/context';
import { FilterItem } from './filterItem';
import { Filter, FilterProps } from '.';

export function SelectFilter({ configItem, wrapped }: FilterProps) {
  const { t } = useTranslation('app');
  const { activeFilters, setFilter } = useFilters();
  const field = (activeFilters[configItem.id] as string) || '';
  const options = useMemo(() => configItem.options || [], [configItem.options]);
  const groupe = useMemo(() => configItem?.groupe || [], [configItem.groupe]);
  const [filteredOptions, setFilteredOptions] = useState<ComboBoxItemType[]>(options);

  const formattedValue = useMemo(() => {
    const label = options.find((o) => o.value === field)?.label;
    const grouped: string[] = [];
    if (groupe.length) {
      groupe.forEach((el) => {
        const value = (activeFilters[el.id] as string) || '';
        if (value) grouped.push(value);
      });
    }
    if (!label && (!groupe.length || !grouped.length)) return '';
    return [label ? `: ${label}` : ': ', ...grouped].filter((el) => el).join(label ? ', ' : '');
  }, [options, groupe, field, activeFilters]);

  function handleSearch(input: string) {
    if (!options) return;
    if (input.trim().length) {
      const byCode = options.filter((opt) => opt.value.toLowerCase() === input.toLowerCase());

      const byName = options.filter((opt) => opt.label.toLowerCase().includes(input.toLowerCase()));

      setFilteredOptions(Array.from(new Set([...byCode, ...byName])));
    } else {
      setFilteredOptions(options);
    }
  }

  function onSelectChange(value: string | undefined) {
    setFilter(configItem.id, value || '');
  }

  if (wrapped) {
    return (
      <div className="relative space-y-2">
        <Label className="absolute left-3 top-[-6px] bg-white px-2 text-xs" htmlFor={configItem.id}>
          {t(`filters.${configItem.id}.label`)}
        </Label>
        <Combobox
          value={field}
          onSelect={onSelectChange}
          items={filteredOptions}
          defaultItems={filteredOptions}
          noResultsMsg={t(`filters.${configItem.id}.noResultsMsg`)}
          placeholder={t(`filters.${configItem.id}.placeholder`)}
          searchPlaceholder={t(`filters.${configItem.id}.searchPlaceholder`)}
          className="w-full"
          defaultOpen={false}
          onSearch={handleSearch}
        />
      </div>
    );
  }

  return (
    <FilterItem displayedValue={formattedValue} configItem={configItem}>
      <div className="relative space-y-2">
        <Label className="absolute left-3 top-[-6px] bg-white px-2 text-xs" htmlFor={configItem.id}>
          {t(`filters.${configItem.id}.label`)}
        </Label>
        <Combobox
          value={field}
          onSelect={onSelectChange}
          items={filteredOptions}
          defaultItems={filteredOptions}
          noResultsMsg={t(`filters.${configItem.id}.noResultsMsg`)}
          placeholder={t(`filters.${configItem.id}.placeholder`)}
          searchPlaceholder={t(`filters.${configItem.id}.searchPlaceholder`)}
          className="w-full"
          defaultOpen={false}
          onSearch={handleSearch}
        />
      </div>
      {groupe.map((item) => (
        <Filter configItem={item} wrapped key={item.id} />
      ))}
    </FilterItem>
  );
}
